import { toast } from 'react-toastify';
import axios from 'axios';
import constants from '../constants/constants';

export const StringToDateConverter = (date) => {

    if (date instanceof Date) {
        return date
    }

    else {
        if ( x == "Invalid Date" || x == null) {
            return null
        }

        var data = date
        var dateParts = data.split("-");
        var x = new Date(dateParts[2], dateParts[1] - 1, dateParts[0])

        return x
    }
    
}


import React from 'react';
import '../styles/Global.css';
import '../styles/HomeButton.css';
import Button from 'react-bootstrap/Button';


const HomeButton = (props) =>{

    return (
        <div className="buttoncontainer">
            <h5 className="buttontext textbold text-white">{props.title}</h5><br/>
            <p className="buttontext otherbuttontext text-white">{props.body}</p> 
            <Button onClick={props.clickeventhandler}  className={`buttonstyle buttontextsize ${props.btntype}`} size="lg">{props.name}</Button>
        </div>
    )
    
}

export default HomeButton
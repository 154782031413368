import React from 'react';
import '../styles/Global.css';

const Footer = () => {
    return (
        <>
            <div className="footer">

                <p className="footer-text">COPYRIGHT 2023 © DIRECTORATE OF CITIZENSHIP & IMMIGRATION CONTROL</p>

            </div>
            
        </>
    )
}

export default Footer;
import logo from './logo.svg';
import './App.css';
import './styles/Global.css';
import './styles/Home.css';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import dcic from './assets/img/dcic.png';
import coatofarms from './assets/img/coatofarms.png';
import Footer from './components/Footer';
import HomeMenu from './components/HomeMenu';
import Button from 'react-bootstrap/Button';
import HomeButton from './components/HomeButton';
import constants from './constants/constants';
import passports3 from './assets/img/passports2.png';
import Header from './components/Header';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

function App() {
  
    const opasapplication = () => {
        window.location.href = constants.opas+'/start'
    }

    const opascontinue = () => {
        window.location.href = constants.opas+'/resume'
    }

    const opasschedule = () => {
        window.location.href = constants.opas+'/payment_status'
    }

    const opasstatus = () => {
        window.location.href = constants.opas+'/check_status'
    }

    return (
        
        <>
            <Header/>
            <div className="home-container">
                <HomeMenu/>
                <Container className="">
                    
                    <Row className="justify-content-md-center">

                        <Col md={5} className="backgroundimage">

                        </Col>

                        <Col md={7} className="homepadding">

                            <div className="homecontainer">

                                <h5 className="innertext">Uganda Passport Application</h5>

                                <div className="buttongroup">

                                            <div className="row">

                                                <div className="col-md-6 text-center btn-content">
                                                    <HomeButton btntype="btn-grad" clickeventhandler={opasapplication} title="Apply" body="Apply for Passport" name="Start New Application" variant="success"/>
                                                </div>
                                                <div className="col-md-6 text-center btn-content">
                                                    <HomeButton btntype="btn-grad-secondary" clickeventhandler={opascontinue} title="Continue" body="Continue Application" name="Resume Save Application" variant="success"/>  
                                                </div>
                                                <div className="col-md-6 text-center btn-content">
                                                    <HomeButton btntype="btn-grad" clickeventhandler={opasschedule} title="Schedule" body="All payments must be complete" name="Schedule An Appointment" variant="primary"/>
                                                </div>
                                                <div className="col-md-6 text-center btn-content">
                                                    <HomeButton btntype="btn-grad-secondary" clickeventhandler={opasstatus} title="Status" body="Check Application Status" name="Check Application Status" variant="primary"/>
                                                </div>

                                            </div>                                    

                                </div>

                                
                            </div>


                          
                        </Col>



                    </Row>
                </Container>
                
            </div>

            <Footer/>
            
            
        </>
        
    );
}

export default App;

import React from 'react';

const constants = {
    "url": "https://passports.go.ug/ctd/api",
    "auth_url": "https://passports.go.ug/ctd/auth/",
    
    "opas": "https://passports.go.ug",
    "opas_start": "https://passports.go.ug/start",

    "ctd_url": "https://passports.go.ug/"
}



export default constants;